// Libraries
import React from 'react';

// Supermove
import {DataLoader, Emoji, Icon, PreventPropagation, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {ResponsiveType, usePopover, useResponsive, useState} from '@supermove/hooks';
import {DocumentModel, UserFlowRunStepModel} from '@supermove/models';
import {Environment} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Document} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import IconButton from '@shared/design/components/IconButton';
import TextTooltip from '@shared/design/components/TextTooltip';
import DocumentV2ByCategory from '@shared/modules/Document/components/DocumentV2ByCategory';
import DocumentV2Pdf from '@shared/modules/Document/components/DocumentV2Pdf';

const PaddingContainer = Styled.View<{responsive: ResponsiveType}>`
  padding: ${({responsive}) => (responsive.mobile ? '16px' : '24px')};
`;

const MicroText = Styled.Text<{responsive: ResponsiveType}>`
  ${Typography.Responsive.Micro}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const openPrintDialog = async ({
  url,
  setIsLoading,
}: {
  url: string;
  setIsLoading: (loading: boolean) => void;
}) => {
  setIsLoading(true);
  try {
    const filename = 'document.pdf';
    url = `${Environment.getAppUrl()}${url}`;
    const pdf = await Document.generatePDFFromUrl({filename, url});
    Document.printFile({file: pdf});
  } catch (error) {
    console.error({error});
  } finally {
    setIsLoading(false);
  }
};

const openPrintPdfDialog = async ({
  setIsLoading,
  documentUuid,
}: {
  setIsLoading: (loading: boolean) => void;
  documentUuid: string;
}) => {
  const filename = 'document.pdf';
  setIsLoading(true);
  try {
    const pdf = await Document.fetchPDFFromS3({filename, documentUuid});
    Document.printFileWithRetries({file: pdf});
  } catch (error) {
    console.error({error});
  } finally {
    setIsLoading(false);
  }
};

const DocumentFlowRunSuccessStep = ({userFlowRunStepUuid}: {userFlowRunStepUuid: string}) => {
  const [isLoading, setIsLoading] = useState(false);
  const responsive = useResponsive();
  const actionsPopover = usePopover();

  const {loading, data} = useQuery<{userFlowRunStep: UserFlowRunStepModel}>(
    DocumentFlowRunSuccessStep.query,
    {
      variables: {
        userFlowRunStepUuid,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const printDocument = (document: DocumentModel) => {
    if (document.organization.features.isEnabledOfficeChangeDocumentFlowViewToPdfView) {
      openPrintPdfDialog({
        documentUuid: document.uuid,
        setIsLoading,
      });
    } else {
      openPrintDialog({
        url: `/0/${document.organization.slug}/documents/v2/${document.uuid}`,
        setIsLoading,
      });
    }
  };

  return (
    <DataLoader loading={loading} data={data}>
      {({loadedData}) => {
        const {userFlowRunStep} = loadedData;
        const {document} = userFlowRunStep;
        return (
          <CollapsibleContent
            TitleIconComponent={<Emoji style={{paddingVertical: 6}} name={'pencil'} />}
            title={document.displayName}
            titleNumberOfLines={1}
            TitleRightComponent={
              <Row>
                {userFlowRunStep.completedAt && responsive.desktop && (
                  <MicroText responsive={responsive}>
                    {Datetime.convertToDisplayDatetime(userFlowRunStep.completedAt)}
                  </MicroText>
                )}
                <Space width={8} />
                <TextTooltip text={'Completed'}>
                  <Icon source={Icon.CircleCheck} color={colors.green.status} size={20} />
                </TextTooltip>
                <Space width={8} />
                <PreventPropagation>
                  <ActionMenuPopover
                    popover={actionsPopover}
                    width={200}
                    actions={[
                      {
                        text: 'Print document',
                        isLoading,
                        onPress: () => printDocument(document),
                      },
                    ]}
                  >
                    <IconButton
                      onPress={actionsPopover.handleToggle}
                      source={Icon.EllipsisV}
                      isSecondary
                    />
                  </ActionMenuPopover>
                </PreventPropagation>
              </Row>
            }
            primaryActionText={!responsive.mobile ? 'Print' : undefined}
            handlePrimaryAction={() => printDocument(document)}
            defaultCollapsed
            isSubmittingPrimaryAction={isLoading}
          >
            {document.organization.features.isEnabledOfficeChangeDocumentFlowViewToPdfView ? (
              <DocumentV2Pdf document={document} viewerWidth={responsive.mobile ? 336 : 792} />
            ) : (
              <PaddingContainer responsive={responsive}>
                <DocumentV2ByCategory
                  document={document}
                  viewerWidth={responsive.mobile ? 336 : 792}
                />
              </PaddingContainer>
            )}
          </CollapsibleContent>
        );
      }}
    </DataLoader>
  );
};

DocumentFlowRunSuccessStep.query = gql`
  ${DocumentV2ByCategory.fragment}
  ${DocumentV2Pdf.fragment}

  query DocumentFlowRunSuccessStep($userFlowRunStepUuid: String!) {
    ${gql.query}
    userFlowRunStep(uuid: $userFlowRunStepUuid) {
      id
      uuid
      completedAt
      document {
        id
        uuid
        displayName
        organization {
          id
          slug
          features {
            isEnabledOfficeChangeDocumentFlowViewToPdfView: isEnabled(
              feature: "OFFICE_CHANGE_DOCUMENT_FLOW_VIEW_TO_PDF_VIEW"
            )
          }
        }
        ...DocumentV2ByCategory
        ...DocumentV2Pdf
      }
    }
  }
`;

export default DocumentFlowRunSuccessStep;
