// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {UserFlowRunModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import DocumentFlowRunSuccessStep from 'modules/DocumentFlowRun/DocumentFlowRunSuccessPage/components//DocumentFlowRunSuccessStep';

const Container = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  width: ${({responsive}) => (responsive.mobile ? '100%' : '796px')};
  padding-horizontal: ${({responsive}) => (responsive.mobile ? 20 : 0)}px;
  align-self: center;
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Subtitle = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

type DocumentFlowRunSuccessPageContentProps = {
  userFlowRun: UserFlowRunModel;
  title?: string;
  subtitle?: string;
  isCancelled?: boolean;
  cancelledMessage?: string;
};

const DocumentFlowRunSuccessPageContent = ({
  userFlowRun,
  title,
  subtitle,
}: DocumentFlowRunSuccessPageContentProps) => {
  const responsive = useResponsive();
  return (
    <Container responsive={responsive}>
      <Space height={60} />
      <React.Fragment>
        <Title responsive={responsive}>{title}</Title>
        <Space height={8} />
        <Subtitle responsive={responsive}>{subtitle}</Subtitle>
        <Space height={36} />
        {userFlowRun.userFlowRunSteps.map((userFlowRunStep, index) => (
          <React.Fragment key={index}>
            <DocumentFlowRunSuccessStep userFlowRunStepUuid={userFlowRunStep.uuid} />
            <Space height={24} />
          </React.Fragment>
        ))}
      </React.Fragment>
      <Space height={36} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowRunSuccessPageContent.fragment = gql`
  fragment DocumentFlowRunSuccessPageContent on UserFlowRun {
    id
    userFlowRunSteps {
      id
      uuid
    }
  }
`;

export default DocumentFlowRunSuccessPageContent;
