// Libraries
import React from 'react';

// Supermove
import {PageLoader} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {UserFlowRunModel} from '@supermove/models';
import {NavigationType} from '@supermove/navigation/src/NavigationTypes';

// App
import UserFlowRunStepStatus from '@shared/modules/UserFlows/enums/UserFlowRunStepStatus';
import DocumentFlowRunNotFoundPageContent from 'modules/DocumentFlowRun/DocumentFlowRunNotFoundPageContent';

const getNextStep = (userFlowRun: UserFlowRunModel) => {
  const {userFlowRunSteps} = userFlowRun;
  const nextStep = userFlowRunSteps
    .sort((a, b) => a.index - b.index)
    .find((step) => step.status === UserFlowRunStepStatus.INCOMPLETE);
  return nextStep;
};

const handleRedirect = ({
  userFlowRun,
  slug,
  navigator,
}: {
  userFlowRun: UserFlowRunModel;
  slug: string;
  navigator: NavigationType;
}) => {
  const {project} = userFlowRun;

  if (userFlowRun.isExpired || project.isCancelled || userFlowRun.userFlowRunSteps.length === 0) {
    navigator.replace('DocumentFlowRunExpiredPage', {
      slug,
      userFlowRunUuid: userFlowRun.uuid,
    });
  } else {
    const nextStep = getNextStep(userFlowRun);
    if (nextStep) {
      navigator.replace('DocumentFlowRunStepPage', {
        slug,
        userFlowRunUuid: userFlowRun.uuid,
        userFlowRunStepUuid: nextStep.uuid,
      });
    } else {
      navigator.replace('DocumentFlowRunSuccessPage', {
        slug,
        userFlowRunUuid: userFlowRun.uuid,
      });
    }
  }
};

const DocumentFlowRunPage = () => {
  const {navigator, params} = useNavigation();

  const {data, loading} = useQuery<{userFlowRun: UserFlowRunModel}>(DocumentFlowRunPage.query, {
    fetchPolicy: 'network-only',
    variables: {userFlowRunUuid: params.userFlowRunUuid},
  });

  return (
    <PageLoader loading={loading} data={data}>
      {({loadedData}) => {
        if (!loadedData.userFlowRun) {
          return <DocumentFlowRunNotFoundPageContent />;
        }
        handleRedirect({userFlowRun: loadedData.userFlowRun, slug: params.slug, navigator});
        return null;
      }}
    </PageLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowRunPage.query = gql`
  query DocumentFlowRunPage($userFlowRunUuid: String!) {
    ${gql.query}
    userFlowRun(uuid: $userFlowRunUuid) {
      id
      uuid
      isExpired
      isCompleted
      expirationDatetime
      userFlowRunSteps {
        id
        uuid
        index
        status
      }
      project {
        id
        uuid
      }
    }
  }
`;

export default DocumentFlowRunPage;
