// Libraries
import React from 'react';

// Supermove
import {PageLoader, ScrollView, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {ResponsiveType, useNavigation, useResponsive} from '@supermove/hooks';
import {UserFlowRunModel} from '@supermove/models';

// App
import DocumentFlowRunSuccessPageContent from 'modules/DocumentFlowRun/DocumentFlowRunSuccessPage/components/DocumentFlowRunSuccessPageContent';

const Container = Styled.View<ResponsiveType>`
  flex: 1;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: ${({mobile}) => (mobile ? '100%' : '796px')};
  padding-horizontal: ${({mobile}) => (mobile ? 20 : 0)}px;
`;

const getSubtitleText = () => {
  return `If you have any questions, please contact us by phone or email.`;
};

const DocumentFlowRunSuccessPage = () => {
  const {navigator, params} = useNavigation();
  const responsive = useResponsive();

  const {data, loading} = useQuery<{userFlowRun: UserFlowRunModel}>(
    DocumentFlowRunSuccessPage.query,
    {
      fetchPolicy: 'network-only',
      variables: {uuid: params.userFlowRunUuid},
    },
  );

  return (
    <PageLoader loading={loading} data={data}>
      {({loadedData}) => {
        const {userFlowRun} = loadedData;
        if (!userFlowRun.isCompleted) {
          navigator.replace('DocumentFlowRunPage', {
            slug: params.slug,
            userFlowRunUuid: params.userFlowRunUuid,
          });
        }

        return (
          <Container {...responsive}>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DocumentFlowRunSuccessPageContent
                userFlowRun={userFlowRun}
                title={'Your document flow is complete!'}
                subtitle={getSubtitleText()}
              />
            </ScrollView>
          </Container>
        );
      }}
    </PageLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowRunSuccessPage.query = gql`
  ${DocumentFlowRunSuccessPageContent.fragment}

  query DocumentFlowRunSuccessPage($uuid: String!) {
    ${gql.query}
    userFlowRun(uuid: $uuid) {
      id
      uuid
      isCompleted
      project {
        id
        organization {
          id
          slug
        }
      }
      ...DocumentFlowRunSuccessPageContent
    }
  }
`;

export default DocumentFlowRunSuccessPage;
